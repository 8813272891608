import React, { useEffect, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import firebase from '../utils/firebase';
// import AppRoutes from './Routes';
import { AuthProvider } from '../contexts/auth.context';
import ScrollToTop from '../components/ScrollToTop';
import { theme } from '../theme';
import { config } from '../config';
import { SearchProvider } from '../contexts/search.context';
import { CreateSubscriberProfileProvider } from '../contexts/newSubscriberProfile.context';
import Maintenance from './Maintenance';
declare var OpenPay: any;

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const App = () => {
  const openPayLoaded = useRef(false);
  const googleMapsScriptloaded = useRef(false);
  firebase.analytics();
  // console.log(analytics.app);

  useEffect(() => {
    const openPaySetUp = () => {
      OpenPay.setId(config.openpay.id);
      OpenPay.setApiKey(config.openpay.key);
      OpenPay.setSandboxMode(process.env.REACT_APP_ENVIRONMENT === 'production' ? false : true);
      const deviceSessionId = OpenPay.deviceData.setup();
      sessionStorage.setItem('sessionId', deviceSessionId);
      // console.log('Session ID: ' + deviceSessionId);
      openPayLoaded.current = true;
    };

    // TODO -> load scripts with defer tag and use this effect after scripts have loaded
    if (!openPayLoaded.current) openPaySetUp();
  }, []);

  // Load google maps api only once on first render
  if (typeof window !== 'undefined' && !googleMapsScriptloaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }
    googleMapsScriptloaded.current = true;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <AuthProvider>
            <SearchProvider>
              <CreateSubscriberProfileProvider>
                {/* <AppRoutes /> */}
                <Maintenance />
              </CreateSubscriberProfileProvider>
            </SearchProvider>
          </AuthProvider>
        </MuiPickersUtilsProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
