import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  typography: {
    body1: {
      fontFamily: 'Open Sans, sans-serif',
    },
    body2: {
      fontFamily: 'Open Sans, sans-serif',
    },
    button: {
      fontFamily: 'Open Sans, sans-serif',
    },
    overline: {
      fontFamily: 'Open Sans, sans-serif',
    },
    caption: {
      fontFamily: 'Open Sans, sans-serif',
    },
    subtitle1: {
      fontFamily: 'Luckiest Guy, sans-serif',
      fontSize: '1.5rem',
    },
    subtitle2: {
      fontFamily: 'Luckiest Guy, sans-serif',
      fontSize: '1.25rem',
    },
    h1: {
      fontFamily: 'Luckiest Guy, sans-serif',
      fontSize: '2.75rem',
    },
    h2: {
      fontFamily: 'Luckiest Guy, sans-serif',
      fontSize: '2.5rem',
    },
    h3: {
      fontFamily: 'Luckiest Guy, sans-serif',
      fontSize: '2.25rem',
    },
    h4: {
      fontFamily: 'Luckiest Guy, sans-serif',
      fontSize: '2rem',
    },
    h5: {
      fontFamily: 'Luckiest Guy, sans-serif',
      fontSize: '1.75rem',
    },
    h6: {
      fontFamily: 'Luckiest Guy, sans-serif',
      fontSize: '1.5rem',
    },
  },
  palette: {
    primary: {
      main: '#61AEDD',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#A08CBD',
      contrastText: '#ffffff',
    },
    info: {
      main: '#50C1C4',
      contrastText: '#ffffff',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});
