import React, { useState, useContext, useMemo, createContext, useCallback, useEffect } from 'react';

import ServicesService, { ServiceType } from '../services/services.service';

const initialQuery = {
  coords: { lat: null, lng: null },
  types: [],
  distance: 10,
};
const initialPagination = {
  nextPage: 2,
  prevPage: null,
  total: 0,
};
const initialContext: SearchContext = {
  results: { services: [], profiles: [] },
  query: initialQuery,
  loading: false,
  setQuery: () => {},
  setResults: () => {},
  setShowSearchButton: () => false,
  fetchData: () => Promise.resolve(),
  pagination: initialPagination,
  showSearchButton: true,
  useLocation: false,
  setUseLocation: () => false,
};

const SearchContext = createContext(initialContext);

export const SearchProvider: React.FC = ({ children }) => {
  const [results, setResults] = useState<Results>({ services: [], profiles: [] });
  const [query, setQuery] = useState<SearchQuery>(initialQuery);
  const [loading, setLoading] = useState(false);
  const [showSearchButton, setShowSearchButton] = useState(false);
  const [useLocation, setUseLocation] = useState(false);
  const [pagination, setPagination] = useState(initialPagination);

  useEffect(() => {
    if (results.services.length > 0) {
      setShowSearchButton(false);
    } else if (!showSearchButton) {
      setShowSearchButton(true);
    }
    // eslint-disable-next-line
  }, [results]);

  const fetchData = useCallback(
    async (queryParams?: UrlQueryParams) => {
      if (query) {
        const _service = new ServicesService();
        const { coords, distance, types } = query;

        setLoading(true);
        const res = await _service.getNearbyServices(
          { ...coords, distance, types },
          queryParams
            ? {
                page: queryParams.page ? queryParams.page : 1,
                limit: queryParams.limit ? queryParams.limit : 200,
              }
            : {},
        );
        setPagination(res.pagination);
        setLoading(false);

        if (res.success) {
          setResults(res.data);
        } else setResults({ services: [], profiles: [] });
      } else return;
    },
    [query],
  );

  const providerValue = useMemo(
    () => ({
      results,
      query,
      setQuery,
      fetchData,
      loading,
      pagination,
      setResults,
      showSearchButton,
      setShowSearchButton,
      useLocation,
      setUseLocation,
    }),
    [query, results, loading, fetchData, pagination, setResults, showSearchButton, useLocation],
  );

  return <SearchContext.Provider value={providerValue} children={children} />;
};

export const useSearchContext = () => useContext(SearchContext);
export default SearchContext;

interface SearchContext {
  results: Results;
  setResults: React.Dispatch<React.SetStateAction<Results>>;
  query: SearchQuery;
  setQuery: React.Dispatch<React.SetStateAction<SearchQuery>>;
  fetchData: (x?: UrlQueryParams) => Promise<void>;
  loading: boolean;
  pagination: Pagination;
  showSearchButton: boolean;
  setShowSearchButton: React.Dispatch<React.SetStateAction<boolean>>;
  useLocation: boolean;
  setUseLocation: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Pagination {
  nextPage: number | null;
  prevPage: number | null;
  total: number;
}

interface UrlQueryParams {
  page?: number | null;
  limit?: number;
}

export type SearchQuery = {
  coords: { lat: number | null; lng: number | null };
  distance: number;
  types: ServiceType[];
};

type Results = {
  services: NearbyService[];
  profiles: ServiceProfile[];
};

export interface NearbyService {
  _id: string;
  formattedAddress: string;
  service: ServiceType;
  user: {
    name: string;
    avatar: null | {
      publicId: string;
      url: string;
      path: string;
    };
  };
  subscriberProfile: {
    rating: number;
    ratingsCount: number;
    _id: string;
  };
  coords: { lat: number; lng: number };
}

export interface ServiceProfile {
  _id: string;
  user: {
    name: string;
    avatar: null | { url: string; path: string; publicId: string };
  };
  rating: number;
  ratingsCount: number;
  services: ServiceType[];
}
