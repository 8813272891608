import axios, { AxiosInstance } from 'axios';
import { config } from '../config';

export default class ServicesService {
  api: AxiosInstance;
  accessToken: string | null;

  constructor() {
    this.api = axios.create({
      baseURL: `${config.api.baseUrl}/services`,
    });
    this.accessToken = localStorage.getItem('accessToken');
  }

  createServices = async (data: { services: CreateServiceRequest[]; captcha: string }) => {
    try {
      const response = await this.api.post('/', data, {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { error: 'Ocurrió un error. Intente nuevamente.' };
    }
  };

  deleteService = async (serviceId: string) => {
    try {
      const response = await this.api.delete(`/${serviceId}`, {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { error: 'Ocurrió un error. Intente nuevamente.' };
    }
  };

  editService = async (data: Partial<CreateServiceRequest>, serviceId: string) => {
    try {
      const response = await this.api.put(
        `/${serviceId}`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { error: 'Ocurrió un error. Intente nuevamente.' };
    }
  };

  getService = async (serviceId: string) => {
    try {
      const response = await this.api.get(`/${serviceId}`);

      return response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { error: 'Ocurrió un error. Intente nuevamente.' };
    }
  };

  getNearbyServices = async (data: NearbyRequest, params?: Partial<QueryParams>) => {
    try {
      const response = await this.api.post(
        '/nearby',
        { ...data },
        {
          params,
        },
      );

      return response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { error: 'Ocurrió un error. Intente nuevamente.' };
    }
  };
}

interface QueryParams {
  page: number;
  limit: number;
}

export interface NearbyRequest {
  lat: number | null;
  lng: number | null;
  distance: number;
  types: ServiceType[];
}

export interface CreateServiceRequest {
  type: ServiceType;
  cost: number;
  lat: number;
  lng: number;
  placeId: string;
  formattedAddress: string;
}

export interface Service {
  cost: number;
  type: ServiceType;
  location: {
    coordinates: number[];
    formattedAddress: string;
    placeId: string;
  };
  _id: string;
}

export type ServiceCosts = { [key in ServiceType]: number };

export const serviceTypeMap = {
  'pet-sit': 'Pensión',
  daycare: 'Guardería',
  bath: 'Baño',
  walk: 'Paseo',
};

export type ServiceCounts = {
  [key in ServiceType]: number;
};

export type ServiceType = 'pet-sit' | 'daycare' | 'bath' | 'walk';
