import React, { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {}

const ScrollToTop: React.FC<Props> = (props) => {
  const {
    location: { pathname },
  } = props;
  useEffect(() => {
    try {
      // new API
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // Fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  // renders nothing, since nothing is needed
  return null;
};

export default withRouter(ScrollToTop);
