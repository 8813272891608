import axios, { AxiosInstance } from 'axios';
import { config } from '../config';

export default class AuthService {
  api: AxiosInstance;
  accessToken: string | null;

  constructor() {
    this.api = axios.create({
      baseURL: `${config.api.baseUrl}/auth`,
    });
    this.accessToken = localStorage.getItem('accessToken');
  }

  logIn = async (data: LoginRequest) => {
    try {
      const response = await this.api.post('/login', { ...data });
      return response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { error: 'Ocurrió un error. Intente nuevamente.' };
    }
  };

  logOut = async () => {
    localStorage.clear();

    try {
      const response = await this.api.get('/logout');

      return response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { error: 'Ocurrió un error. Intente nuevamente.' };
    }
  };

  registerUser = async (data: RegisterRequest) => {
    try {
      const response = await this.api.post('/register', { ...data });
      return response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { error: 'Ocurrió un error. Intente nuevamente.' };
    }
  };

  currentUser = async (accessToken: string | null) => {
    try {
      const response = await this.api.get('/current-user', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { error: 'Ocurrió un error. Intente nuevamente.' };
    }
  };

  forgotPassword = async (data: { email: string; captcha: string }) => {
    try {
      const response = await this.api.post('/forgot-password', { ...data });
      return response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { error: 'Ocurrió un error. Intente nuevamente.' };
    }
  };

  resetPassword = async (data: { password: string; captcha: string }, token: string) => {
    try {
      const response = await this.api.put(`/reset-password/${token}`, data);
      return response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { error: 'Ocurrió un error. Intente nuevamente.' };
    }
  };

  verifyEmail = async (token: string) => {
    try {
      const response = await this.api.get(`/confirm/${token}`);
      return response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { error: 'Ocurrió un error. Intente nuevamente.' };
    }
  };

  sendConfirmationEmail = async (data: { email: string; captcha: string }) => {
    try {
      const response = await this.api.post('/send-confirmation', { ...data });
      return response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { error: 'Ocurrió un error. Intente nuevamente.' };
    }
  };

  updateToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');

    try {
      const response = await this.api.post('/token', { refreshToken });
      return response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { error: 'Ocurrió un error. Intente nuevamente.' };
    }
  };
}

export interface RegisterRequest {
  name: string;
  dateOfBirth: string;
  email: string;
  password: string;
  captcha: string;
  gender?: number;
}

interface LoginRequest {
  email: string;
  password: string;
}
