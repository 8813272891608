import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { FbIcon, IgIcon } from '../components/Icons';
import logo from '../assets/images/logo-min.png';

const Maintenance = () => {
  return (
    <div className='column page-wrapper' style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      <Typography
        align='center'
        style={{ marginTop: 'auto', padding: 20 }}
        variant='h1'
        color='textSecondary'
      >
        Estamos en mantenimiento
        <Typography align='center' variant='body1' color='textSecondary'>
          Lamentamos la inconveniencia. Reestableceremos el servicio lo antes posible.
        </Typography>
      </Typography>

      <footer style={{ marginTop: 'auto', padding: 20 }}>
        <Grid style={{ width: '100vw' }} container spacing={3}>
          <Grid className='column' style={{ justifyContent: 'center' }} item xs={12}>
            <img style={{ width: '100%', maxWidth: 300 }} src={logo} alt='Logo' />
            <div style={{ marginTop: 20 }}>
              <a
                style={{ margin: 10, color: '#61AEDD' }}
                href='https://www.facebook.com/PupBuddyMX'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FbIcon />
              </a>
              <a
                style={{ margin: 10, color: '#61AEDD' }}
                href='https://www.instagram.com/pupbuddymx/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <IgIcon />
              </a>
            </div>
          </Grid>
        </Grid>
        <Typography
          variant='caption'
          color='primary'
          style={{ marginTop: '20px', display: 'block', textAlign: 'center' }}
        >
          Derechos Reservados Pup-Buddy &copy; {new Date().getFullYear()}
        </Typography>
      </footer>
    </div>
  );
};

export default Maintenance;
