export const config = {
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL as string,
  },
  googleMaps: {
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  },
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY as string,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL as string,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID as string,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET as string,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID as string,
    appId: process.env.REACT_APP_FIREBASE_APP_ID as string,
    measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID as string,
  },
  openpay: {
    id: process.env.REACT_APP_OPENPAY_ID as string,
    key: process.env.REACT_APP_OPENPAY_KEY as string,
  },
};
