import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import AuthService from '../services/auth.service';

const initialAuthContext: AuthContext = {
  user: null,
  setUser: () => {},
};

const AuthContext = createContext(initialAuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const localUser = localStorage.getItem('user');
  const parsedUser = localUser ? (JSON.parse(localUser) as IUser) : null;
  const [user, setUser] = useState<IUser | null>(parsedUser);
  const [accessTokenState, setAccessTokenState] = useState<string | null>(null);
  const loaded = useRef(false);
  const tokenUpdateCount = useRef(0);
  const providerValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  // Effect to automatically update token and get current user
  useEffect(() => {
    const updateToken = async () => {
      const _auth = new AuthService();
      const res = await _auth.updateToken();

      if (res.success) {
        localStorage.setItem('accessToken', res.accessToken);
        tokenUpdateCount.current++;
        if (user) loaded.current = true;
      } else {
        localStorage.clear();
        setUser(null);
      }

      setAccessTokenState(res.accessToken || null);
    };

    const getCurrentUser = async () => {
      const accessToken = localStorage.getItem('accessToken');
      const _auth = new AuthService();
      const res = await _auth.currentUser(accessToken as string);

      loaded.current = true;

      if (res.success) {
        setUser(res.data);
        localStorage.setItem('user', JSON.stringify(res.data));
      } else if (res.errorCode === 'auth/token-expired') {
        const tokenRes = await _auth.updateToken();
        if (tokenRes.success) {
          localStorage.setItem('accessToken', tokenRes.accessToken);
          await getCurrentUser();
        } else {
          localStorage.clear();
          setUser(null);
        }

        return;
      } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        setUser(null);
      }
    };

    if (!localStorage.getItem('user')) return;

    if (!loaded.current && localStorage.getItem('user')) getCurrentUser();

    if (user && loaded.current) {
      // const tokenTimer = setTimeout(updateToken, 10000); // update token 10 seconds
      const tokenTimer = setTimeout(updateToken, 60000 * 55); // update token 55 minutes

      return () => clearTimeout(tokenTimer);
    }
  }, [accessTokenState, user]);

  return <AuthContext.Provider value={providerValue} children={children} />;
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthContext;

interface AuthContext {
  user: IUser | null;
  setUser: Dispatch<SetStateAction<IUser | null>>;
}

export interface IUser {
  gender: 'N/A' | 'Masculino' | 'Femenino';
  role: 'user' | 'subscriber';
  avatar: Avatar | null;
  _id: string;
  name: string;
  email: string;
  potentialSubscriber: boolean;
  dateOfBirth: Date;
  profileId: string | null;
  subscriptionId: string | null;
}

interface Avatar {
  url: string;
  path?: string;
  publicId?: string;
}
