import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react';
import { Availability } from '../services/subscriber.service';

type CreateSubscriberProfileContext = {
  subscriberProfile: SubscriberProfile;
  setSubscriberProfile: Dispatch<SetStateAction<SubscriberProfile>>;
};

const initialValue: CreateSubscriberProfileContext = {
  subscriberProfile: {
    email: '',
    availability: null,
    description: '',
    dogSizes: null,
    servicesOffered: null,
  },
  setSubscriberProfile: () => {},
};

const Context = createContext(initialValue);

export const CreateSubscriberProfileProvider: React.FC = ({ children }) => {
  const [subscriberProfile, setSubscriberProfile] = useState<SubscriberProfile>({
    email: '',
    availability: null,
    description: '',
    dogSizes: null,
    servicesOffered: null,
  });
  const providerValue = useMemo(() => ({ subscriberProfile, setSubscriberProfile }), [
    subscriberProfile,
  ]);

  return <Context.Provider value={providerValue} children={children} />;
};

export interface SubscriberProfile {
  email: string;
  description: string;
  servicesOffered: Services[] | null;
  availability: Availability | null;
  dogSizes: DogSizes[] | null;
  mobilePhone?: string;
  gallery?: any;
}

type DogSizes = 'small' | 'medium' | 'big' | 'giant';

type Services = 'bath' | 'pet-sit' | 'daycare' | 'walk';

export default Context;
